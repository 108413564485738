import { Env, ModelNames, ProductNames } from './constants/exports';

const config = new Object();

config[Env.DEVELOP] = {
	theme: {
		palette: {
			primary: {
				main: '#D13438',
			},
		},
	},
	amplify: {
		Auth: {
			identityPoolId: 'ap-northeast-1:4fe8d94e-8656-4886-a87a-bc2a41db0228',
			region: "ap-northeast-1",
			userPoolId: "ap-northeast-1_Ca9VgTmXl",
			userPoolWebClientId: "5csii105v3sn0goged2n5josme",
		},
		Storage: {
			AWSS3: {
				bucket: 'dev-tapi-storage',
				region: "us-west-1",
			},
		},
	},
	tapi: {
		endpoint: 'https://hwy8wash2k.execute-api.us-west-1.amazonaws.com/dev',
	},
	permissions: {
		'payterm': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.PT_750_DEBUG.value},
			{type: 'model_name', value: ModelNames.PT_750_HOUSE_APP.value},
			{type: 'model_name', value: ModelNames.PT_750_VALUEDESIGN_DEBUG.value},
			{type: 'model_name', value: ModelNames.PT_750_PREPAID_DEBUG.value},
		],
		'businvterm': [
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_PORTABLE_DEBUG.value},
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_PORTABLE.value},
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_ADMIN_PORTABLE_DEBUG.value},
		],
		'tapp': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.TAPP_DEV.value},
			{type: 'model_name', value: ModelNames.RAKU2_DRIVER_APP_DEV.value},
			{type: 'model_name', value: ModelNames.RAKU2_DRIVER_APP.value},
		],
		'abt': [
			{type: 'model_name', value: ModelNames.PT_850.value},
			{type: 'model_name', value: ModelNames.ABT_TABLET_DEBUG.value},
		],
		'didi': [
			{type: 'model_name', value: ModelNames.DIDI.value},
		],
		'sride': [
			{type: 'model_name', value: ModelNames.SRIDE.value},
			{type: 'model_name', value: ModelNames.SRIDE_DEV.value},
		],
		'uber': [
			{type: 'model_name', value: ModelNames.UBER_CAB.value},
		],
		'bus-ticket': [
			{type: 'model_name', value: ModelNames.BUS_TICKET_DEBUG.value},
			{type: 'product_name', value: ProductNames.PT_830.value},
		],
	},
};

config[Env.STAGING] = {
	theme: {
	},
	amplify: {
		Auth: {
			identityPoolId: 'ap-northeast-1:4fe8d94e-8656-4886-a87a-bc2a41db0228',
			region: "ap-northeast-1",
			userPoolId: "ap-northeast-1_Ca9VgTmXl",
			userPoolWebClientId: "5csii105v3sn0goged2n5josme",
		},
		Storage: {
			AWSS3: {
				bucket: 'stg-tapi-storage',
				region: "us-west-1",
			},
		},
	},
	tapi: {
		endpoint: 'https://4bjunvkw5a.execute-api.us-west-1.amazonaws.com/stg',
	},
	permissions: {
		'payterm': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.PT_750_DEBUG.value},
			{type: 'model_name', value: ModelNames.PT_750_HOUSE_APP.value},
			{type: 'model_name', value: ModelNames.PT_750_VALUEDESIGN_DEBUG.value},
			{type: 'model_name', value: ModelNames.PT_750_PREPAID_DEBUG.value},
		],
		'businvterm': [
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_PORTABLE_DEBUG.value},
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_PORTABLE.value},
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_ADMIN_PORTABLE_DEBUG.value},
		],
		'tapp': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.TAPP_STG.value},
			{type: 'model_name', value: ModelNames.RAKU2_DRIVER_APP_STG.value},
			{type: 'model_name', value: ModelNames.RAKU2_DRIVER_APP.value},
		],
		'abt': [
			{type: 'model_name', value: ModelNames.PT_850.value},
			{type: 'model_name', value: ModelNames.ABT_TABLET_DEBUG.value},
		],
		'didi': [
			{type: 'model_name', value: ModelNames.DIDI.value},
		],
		'sride': [
			{type: 'model_name', value: ModelNames.SRIDE.value},
			{type: 'model_name', value: ModelNames.SRIDE_DEV.value},
		],
		'uber': [
			{type: 'model_name', value: ModelNames.UBER_CAB.value},
		],
		'bus-ticket': [
			{type: 'model_name', value: ModelNames.BUS_TICKET_DEBUG.value},
			{type: 'product_name', value: ProductNames.PT_830.value},
		],
	},
};

config[Env.PRODUCTION] = {
	theme: {
		palette: {
			primary: {
				main: '#000',
			},
		},
	},
	amplify: {
		Auth: {
			identityPoolId: 'ap-northeast-1:2c9556ad-8a11-45c8-bccc-2dcfb71c2147',
			region: "ap-northeast-1",
			userPoolId: "ap-northeast-1_97HZyfK38",
			userPoolWebClientId: "2cmimjoht68f5qon8msg9vamjl",
		},
		Storage: {
			AWSS3: {
				bucket: 'tapi-storage',
				region: "ap-northeast-1",
			},
		},
	},
	tapi: {
		endpoint: 'https://wrmh3avp17.execute-api.ap-northeast-1.amazonaws.com/prod',
	},
	permissions: {
		'payterm': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.PT_750.value},
			{type: 'model_name', value: ModelNames.PT_750_VALUEDESIGN.value},
			{type: 'model_name', value: ModelNames.PT_750_PREPAID.value},
		],
		'businvterm': [
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_PORTABLE.value},
			{type: 'model_name', value: ModelNames.YOKOHAMA_SENIOR_PASS_ADMIN_PORTABLE.value},
		],
		'tapp': [
			{type: 'product_name', value: ProductNames.IM_A820.value},
			{type: 'model_name', value: ModelNames.TAPP.value},
			{type: 'model_name', value: ModelNames.RAKU2_DRIVER_APP.value},
		],
		'abt': [
			{type: 'model_name', value: ModelNames.PT_850.value},
			{type: 'model_name', value: ModelNames.ABT_TABLET.value},
		],
		'didi': [
			{type: 'model_name', value: ModelNames.DIDI.value},
		],
		'sride': [
			{type: 'model_name', value: ModelNames.SRIDE.value},
			{type: 'model_name', value: ModelNames.SRIDE_DEV.value},
		],
		'uber': [
			{type: 'model_name', value: ModelNames.UBER_CAB.value},
		],
		'onetab-rea': [
			{type: 'model_name', value: ModelNames.ONETAB_REA.value},
		],
		'bus-ticket': [
			{type: 'model_name', value: ModelNames.BUS_TICKET.value},
			{type: 'product_name', value: ProductNames.PT_830.value},
		],
	},
};

export default deepFreeze(config);

function deepFreeze(object) {
	// オブジェクトで定義されたプロパティ名を取得
	const propNames = Object.getOwnPropertyNames(object);

	// 自分自身を凍結する前にプロパティを凍結

	for (const name of propNames) {
		const value = object[name];

		if (value && typeof value === "object") {
			deepFreeze(value);
		}
	}

	return Object.freeze(object);
}
